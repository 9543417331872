.App.container {
    margin-left: 0px;
    margin-right: 0px;
	padding-left: 0px;
	padding-right: 0px;
	border: 0;
    min-width: 100%;
    max-width: 100%;
    /*background-color: black;
    min-width: 100%;
    background-image: url("../public/imagens/bkg_geral.jpg");
    background-attachment: initial;
    background-position: 50% 0%;
    background-repeat: repeat-x;*/
}
