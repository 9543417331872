.seleciona_periodo_semana{
    margin-top: 13px;
}
  
.seleciona_periodo{
  max-width: 500px;
  display: flex;
    justify-content: space-between;
  margin-bottom: 5px;
  /*margin-top: 20px;*/
}

.seleciona_municipio{
  display: flex;
    /*justify-content: start; */
}

.valor_resumo_container{
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px !important;
  text-align: center;
}

.valor_resumo{
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}

.titulo_resumo_grafico{
  min-width: 100px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  margin-top: 10%;
}
  