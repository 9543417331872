.grafico_zoom{
    min-height: 500px;
}

.modal-content{
    min-width: 60vw;
    min-height: 90vh;
    margin: 0;
}

div.modal-dialog.modal-lg.modal-dialog-centered{
    min-width: 60vw;
    min-height: 90vh;
}

.modal-footer{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.modal-footer button{
    width: 100px;
}

body { 
    overflow: auto !important; 
    padding-right: 0 !important ;
}