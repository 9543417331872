.container_google_trend{
    margin-top: 15px;
    min-height: 100%;
    max-width: 100%;
}

div.bg-light.text-dark.accordion-body{
    /*background-color: red  !important;*/
    min-width: 10px;
    min-height: 10px;
}

div.container_webbased{
    /*background-color: yellow;*/
    min-width: 100%;
}

.header-dark{
    background-color: #495057 !important;
    color: white !important;
}
  
.header-light{
    background-color: #e9ecef !important;
    color: black !important;
}

#multipleselect_form_web_based{
    margin-top: 20px;
}

.grafico_g{
    min-width: 100% !important;
    max-width: 100%;
    text-align: right;
    padding: 0px;
    margin: 0px;
    margin-top: 5px;
    border-radius: 15px;
    min-height: 60vh !important;
    max-height: 60vh !important;
}

.container_grafico_dash_web{
    /*background-color: red;*/
    max-height: 450px !important;
    min-height: 450px !important;
    margin: 00px;
    padding-right: 35px !important;
    margin-bottom: 5px; 
}

.body_grafico_resumo_grafico_web {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
    min-width: 100% !important;
    width: 100%;
    max-width: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
}