.div_export_planilha_excel:hover .balao_de_texto_excel{
  opacity: 1;
}

.div_export_planilha_excel{
  background-color: #F5f7f7;
  position: relative;
  margin-left: 5px;
  margin-top: 5px;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  padding-left: 4px;
}

.export_planilha{
  max-width: 20px; 
  max-height: 25px; 
  width: 20px; 
  height: 25px; 
  min-width: 20px; 
  min-height: 25px; 
  position: relative;
  right: 2px;
  /*margin-top: -30px;
  right: 15px;*/
  cursor:pointer;
}

.balao_de_texto_excel {
  position: relative;
  margin-top: 0px; /* Posição acima da imagem */
  right: 0px;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  font-size: 10px;
  width: 60px;
}

.divExportPlanilhaCSV_{
  height: 50px;
}

.divExportPlanilhaExcel_{
  height: 50px;
}

.header_tabela{
  display: flex;
  justify-content: space-between;
}

.titulo_tabela{
  min-width: 90%;
  padding-left: 10%;
}

.export_buttons{
  display: flex;
  justify-content: space-between;
  min-height: 10%;
}

.loading{
    top: 0;
    min-width: 100%;
    min-height: 100%;
    min-height: 1900px;
    height: 100%;
    background-color: rgba(50, 50, 50, 0.5);
    position: absolute;
    z-index: 10;
  }
  
  .lds_ring {
    top: 50%;
    left: 50%;
    display: inline-block;
    position: fixed;
    width: 80px;
    height: 80px;
  }
  
  .lds_ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  
  .lds_ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .lds_ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .lds_ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes lds_ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .nome_dados_climaticos{
    font-weight: bold;
  }
  
  .loading .map{
    max-width: 0px;
    max-height: 0px;
  }
  
  /*
  div.leaflet-top.leaflet-right{ display: none !important; width: 0px  !important; height: 0px  !important; }
  */
  
  /*
  .leaflet-popup.leaflet-zoom-animated{
    max-width: 240px;
    min-width: 200px;
    width: auto;
  } 
  
  .leaflet-popup-content-wrapper{
    max-width: 240px;
    min-width: 200px;
    width: auto;
  }*/
  
  .map{
    align-self: center;
    width: 100%;
    min-height: 550px;
    min-height: 100%;
    border-radius: 10px;
    
  }
  
  .mapa{
    margin: 10px;
    width: 95vw;
    min-width: 100%;
    min-height: 450px;
    height: 100%;
    max-height: 100%;
  }
  
  /*.grafico{
    min-width: 70%;
    max-width: 70%;
    max-height: 300px;
    text-align: right;
    padding: 0px;
    margin: 10px;
    border-radius: 15px;
  }*/
  
  .grafico_pg{
    min-width: 70%;
    max-width: 70%;
    max-height: 300px;
    text-align: right;
    padding: 0px;
    margin: 10px;
    border-radius: 15px;
  }
  
  .div_em_linha{
    display: inline-block;
    margin: -5px;
  }
  
  .coluna_maximize{
    max-width: 25px;
    min-width: 25px;
    min-height: 100%;
  }
  
  .table_container{
    margin-top: 15px; 
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
  }
  
  .table {
    padding: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;
    max-height: 523px;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
  }
  
  .space_top{
    margin-top: 25px;
    margin-bottom: 10px;
  }
  
  .slider_div{
    display: flex;
    flex-direction: row;
  }
  
  .numero_tabela{
    text-align: right;
  }
  
  .linha_tabela{
    font-size: 12px;
  }
  
  tr th a img{
    margin-right: 2px;
    width: 20px;
    height: 20px;
    -webkit-filter: hue-rotate(120deg) invert(100%);
  }
  
  .seleciona_municipio{
    margin-top: 5px;
    margin-bottom: 0;
  }
  
  .seleciona_cor_municipio{
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 0;
    width: 200px;
  }
  
  .seleciona_municipio{
    display: flex;
  }
  
  button.button_maximize{
    width: 25px;
    height: 25px;
    font-size: 10px;
    padding: 0;
  }
  
  .float_right{
    position:inherit; 
    inset: 0;
  }
  
  .invert {
    filter: hue-rotate(90deg);
  }
  
  .round {
    border-radius: 25%;
    margin-bottom: 10px;
    margin-top: 5px;
    max-height: 20px;
  }
  
  .previous {
    background-color: #f1f1f1;
    color: black;
    margin-right: 10px;
  }
  
  .next {
    background-color: #f1f1f1;
    color: black;
    margin-left: 10px;
  }
  
  .seleciona_periodo_semana{
    margin-top: 13px;
  }
  
  .seleciona_periodo{
    max-width: 500px;
    display: flex;
      justify-content: space-between;
    margin-bottom: 5px;
    /*margin-top: 20px;*/
  }
  
  .div_top{
    margin-bottom: 15px;
    padding-top: 0px;
    /*margin-top: 50px;*/
  }
  
  .range_container {
    display: flex;
    flex-direction: column;
    max-width: 55%;
    min-width: 55%;
    margin: 8px auto;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
  }
  
  .sliders_control {
    position: relative;
    max-height: 50px;
  }
  
  .range_value {
    position: absolute;
    min-height: 7px;
    min-width: 100%;
    left: 0%;
    border-radius: 5px;
    background-color: #d5d5d5;
  }
  
  a.previous {
    text-decoration: none;
    display: inline-block;
    padding: 0px 8px;
  }
  
  a.previous:hover {
    background-color: #545453;
    color: white;
  }
  
  a.next {
    text-decoration: none;
    display: inline-block;
    padding: 0px 8px;
  }
  
  a.next:hover {
    background-color: #545453;
    color: white;
  }
  
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    /*.range_container input[type='range'] {
      overflow: hidden;
      -webkit-appearance: none;
      background-color: #9a905d;
    } */
  
    /*
    .range_container input[type='range']::-webkit-slider-thumb {
      width: 10px;
      -webkit-appearance: none;
      height: 10px;
      cursor: ew-resize;
      background: #434343;
      box-shadow: -80px 0 0 80px #43e5f7;
    } */
  
  }
  
  
  .invisivel{
    visibility: hidden;
  }
  
  .lado_a_lado{
    display: flex;
    justify-content: flex-start;
  }
  
  .legenda{
    background-color: #2d2d2e;
    color: white;
    min-height: 90px;
    min-width: 100px;
    max-width: 220px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 11px;
  }
  
  .legenda span{
    text-align: center;
    font-weight: bold;
  }
  
  .legenda_linha{
    display: flex;
    justify-content: initial;
    flex-direction: row;
  }
  
  .legenda_linha_cor{
    margin-right: 3px;
    background-color: var(--cor);
    min-width: 15px;
    min-height: 15px;
    max-width: 15px;
    max-height: 15px;
  }
  
  .legenda_linha_texto{
    margin-left: 5px;
  }
  
  .primeira_cor_linha{
    --cor:#beb6f1;
  }
  .segunda_cor_linha{
    --cor:#998fe5;
  }
  .terceira_cor_linha{
    --cor:#9d5fd6;
  }
  .quarta_cor_linha{
    --cor:#8552c5;
  }
  .quinta_cor_linha{
    --cor:#6f19d0;
  }
  .sexta_cor_linha{
    --cor:#5811a9;
  }
  .setima_cor_linha{
    --cor:#3e107b;
  }
  
  @media screen and (max-width: 995px) {
  
    .seleciona_municipio{
      display: block;
      /*justify-content: start; */
    }
  
    .seleciona_cor_municipio{
      margin-top: 0px;
      margin-left: 0px;
      margin-bottom: 0;
      width: 200px;
    }
  
    .loading{
      top: 0;
      min-width: 100%;
      min-height: 100%;
      min-height: 4100px;
      height: 100%;
      background-color: rgba(50, 50, 50, 0.5);
      position: absolute;
      z-index: 10;
    }
  
    .MuiBox-root{
      max-width: 45%;
      padding-bottom: 0px;
      margin-top: 0px;
    }
  
    .seleciona_periodo time {
      padding-top: 5px;
    }
  
    .container_Mapa.container{
      margin-top: 10%;
    }
  
    .seleciona_periodo{
      margin-left: 5%;
      max-width: 90%;
    }
  
    .container_Mapa.container{
      font-size: 14px;
    }
  
    .form_switch{
      font-size: 13px;
    }
  
    .retangulos{
      margin-left: 4%;
      max-width: 90%;
      margin-top: 20px;
      padding-top: 10px;
    }
  
    .mapa{
      margin-left: 5%;
      max-width: 100%;
      min-width: 100px;
    }
  
    /*.div_top{
      display: flex;
      justify-content: start;
    }*/
  }
  