
.multipleselect_rank_checkicon {
  filter: invert(1);
}

.container_grafico {
  width: 100%;
  max-height: 400px;
  margin: 10px;
  margin-bottom: 5px; 
}

.header_grafico {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #54688A;
  color: #fff;
  padding: 0px;
  padding-left: 5px;
  font-size: 13px;
  font-weight: 450;
  font-family: Arial, Helvetica, sans-serif;
}

.body_grafico {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

.img_info{
  margin-right: 10px;
  width: 13px;
  height: 13px;
}

.espacamento{
  min-height: 10px;
  height: 20px;
}

.titulo_semana_ep{
  padding: 0px;
  margin: 0px;
  font-weight: bold;
}

.color-item:no-last{
  margin-right: 5px;
}

.color-item{
  min-width: 35px;
  min-height: 15px;
}


a.nav-link:hover {
  color: rgb(146, 146, 146); /* ou a cor desejada */
}

div.nav-item{
  max-height: 55px !important;
}

a.nav-link {
  max-height: 55px !important; 
  padding: 0px !important;
  margin: 0px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

/*.nav_abas {
  position: fixed;
  margin-top: 13px;
  min-width: 100%;
  z-index: 10;
}

.space_nav_abas{
  min-width: 100%;
  min-height: 60px;
}*/

.linha {
  margin-top: 13px;
  min-width: 100%;
  min-height: 20px;
  overflow: hidden;
  background-color: #eeeeee;
}

.linha a {
  float: left;
  display: block;
  text-align: center;
  padding: 5px;
  text-decoration: none;
}

.active{
  background-color: #b3b3b3;
}

.linha a:hover {
  filter: invert(50%);
}

.MuiAutocomplete-listbox{
  font-size: 13px;
}

#autocomplete_estado{
  width: 170px;
  max-height: 15px;
  height: 15px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.autocomplete_estado_table{
  width: 170px;
  max-height: 15px;
  height: 15px;
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

#autocomplete_municipio{
  width: 150px;
  max-height: 15px;
  height: 15px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

#autocomplete_municipio_table{
  width: 150px;
  max-height: 20px;
  height: 19px;
  margin-top: 0px;
  margin-bottom: 0px;
}

#filtraDQITabela{
  width: 100px;
  max-height: 20px;
  height: 20px;
  margin-left: 20px;
  margin-top: 0px;

}

div #multipleselect_form{
  min-height: 32px;
  width: 260px;
  padding: 0px;
}

#multipleselect_form_table{
  min-height: 32px;
  width: /*140px*/270px;
  padding: 0px;
  margin-left: 20px;
}

#multipleselect_select{
  padding: 3px;
  margin: 0px;
  min-height: 25px;
}

#multipleselect_stack{
  padding: 0px;
  margin: 0px;
  margin-top: 6px;
}

#multipleselect_stack_table{
  min-height: 29px;
}

#multipleselect_chip{
  padding: 0px;
  margin: 0px;
  height: 25px;
}

#multipleselect_chip_table{
  margin-top: 5px;
  padding: 0px;
  height: 25px;
}

#multipleselect_chip{
  padding: 0px;
  margin: 0px;
  height: 20px;
}

#multipleselect_cancelicon{
  height: 15px;
  width: 15px;
}

#multipleselect_checkicon{
  max-height: 15px;
  max-width: 15px;
}

#buscaEstadoText{
  width: 50px;
  max-width: 50px;
}


#filtraProbTabela{
  width: 190px;
  max-height: 20px;
  height: 20px;
  margin-left: 20px;
  margin-top: 0px;
}

#demo-simple-select{
  padding-bottom: 16px !important;
}

.dataSemana{
  margin-left: 10px;
  margin-top: 7px;
  font-size: 10px;
}

.dataSemanaRange{
  margin-left: 0px;
  margin-top: 1px;
  font-size: 10px;
  min-width: 125px;
}

input {
  /*-webkit-box-shadow: 0 0 0px 1000px white inset;*/
  color: #fff;
}

#divBuscaMunicipioText {
  margin-left: 25px;
  margin-bottom: 45px;
  display: flex;
}

.buscaMunicipioText{
  color: red;
}

.square {
  width: 15px; /* Largura do quadrado */
  height: 15px; /* Altura do quadrado */
  /*display: inline-block; /* Permite que os quadrados fiquem um ao lado do outro na tabela */
  margin-right: 5px; /* Margem à direita para espaçamento entre o quadrado e o texto */
}

.legend_ {
  font-family: Arial, sans-serif;
  background: rgba(37, 37, 37, 0.7);
  color: #fff;;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.titulo-legend{
  font-size: 13px;
  font-weight: bold;
}

.divLimpaFiltros{
  background-color: #F5f7f7;
  margin-top: 5px;
  margin-left: 10px;
  right: 45px;
  width: 25px;
  height: 25px;
  border-radius: 3px;
}

.noHover{
  pointer-events: none;
}

#category-select label {
  font-size: 0.75rem;
  letter-spacing: 0.0225rem;
}

#select-button{
  margin-top: .5rem;
  display: flex;
  padding: 0.75rem;
  align-items: center;
  justify-content: space-between;
  max-width: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: .375rem;
  border:  1px solid #252529;
  background-color: #17171A;
}

#selected-value {
  color: #AFABB6;
  font-size: 0.875rem;
  letter-spacing: 0.02625rem;
  max-width: 50px;
  width: 50px;
  min-width: 50px;
}

#options-view-button:focus + #select-button,
#options-view-button:checked + #selected-button {
  outline: 1px solid #A881E6;
}

#category-select:has(#options-view-button:checked) label,
#options-view-button:checked + #selected-button #chevrons {
  color: #A881E6;
}

#options-view-button:checked + #select-button #chevrons [data-lucide="chevron-down"]{
  display: none;
}

#options-view-button:checked + #select-button #chevrons [data-lucide="chevron-up"]{
  display: block;
}

div#category-select{
  position: relative;
  max-width: 50px;
  width: 50px;
  min-width: 50px;
}

#options-view-button{
  all: unset;

  position: absolute;
  inset:  0;

  cursor: pointer;
  z-index: 3;
}

#options {
  margin-top: .50rem;
  border-radius: .375rem;
  border: 1px solid #252529;
  background: #17171A;

  display: none;
}

.option {
  display: flex;
  align-items: center;
  gap: .5rem;

  padding: .75rem;

  width: 50px;
  border-bottom: 1px solid #252529;

  position: relative;
}

.option svg {
  width: 1rem;
  height: 1rem;
}

.option svg:last-child {
  margin-left: auto;
  color: #A881E6;
}

.option:has(input:checked),
.option:hover {
  border-bottom: 1px solid #252529;
  background-color: #252529;
}

.option:has(input:focus){
  outline: 1px solid #A881E6;
}

.option [data-lucide="check"]{
  display: none;
}

.option:has(input:checked) [data-lucide="check"]{
  display: block;
}

.option input[type="radio"]{
  all: unset;
  position: absolute;
  inset:  0;
  cursor: pointer;
}

.option .label {
  color: white;
}

/*.option:nth-child(1) {
  color: red;
}

.option:nth-child(2) {
  color: yellow;
}

.option:nth-child(3) {
  color: green;
} */

#category-select:has(#options-view-button:checked) + #options {
  display: block;
}

.select:has(.option input:checked) #category-select label {
  color: #A881E6;
}

.select:has(.option input:checked) #selected-value {
  color: #FBF9FE;
}

#dtBasicExample tr td{
  font-size: 14px;
}

.control.legend.leaflet-control{
  height: 50px;
  max-width: 105px;
  width: 105px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  background-color: rgb(181,181,181);
}

.custom-select {
  display: inline-block;
  position: relative;
  font-family: Arial;
  border-radius: 5px;
  font-size: 14px;
  width: 90%;
  text-align: center;
}

select.custom-select option:hover::after {
  background-color: #3498db; /* Altere para a cor desejada */
  color: #000; /* Altere para a cor desejada */
}

.custom-select:hover {
  border-color: #b7b7b7;
}

.leaflet-tile-pane {
   /*-webkit-filter: hue-rotate(120deg) invert(100%);*/
}

button.btn.btn-primary{
  background-color:rgba(0,0,0,0.0);    
}

.modal-footer button.btn.btn-primary{
  background-color:rgba(77, 64, 64, 0.6);    
  border: 0;
}

.leaflet-container {
    z-index: 0;
}

.leaflet-pane10-pane{
    pointer-events: none;
}

.leaflet-pane.leaflet-popup-pane{
    z-index: 1500;
    line-height: 200% !important;
}

.custom-popup{
  min-width: 200px;
  max-width: 200px;
}

.leaflet-control-layers.leaflet-control-layers-expanded.leaflet-control{
    background-color: rgba(255, 255, 255, 0.6);
}

/*popup legenda do mapa*/
.leaflet-popup.leaflet-zoom-animated.popup-estilo{ 
  max-width: 200px;
  height: 10px;
  font-size: 15px;
  margin-bottom: 10px;
  pointer-events: none;
}

.informacoes_icone{
  margin-left: 2px;
  margin-bottom: 2px;
  max-width: 12px;
  max-height: 12px;
}

div.leaflet-popup-content{
  line-height: 98% !important;
  padding: 0px;
  margin: 0px;
  margin-left: 5px;
  border: 0px;
}

div.leaflet-popup-content-wrapper{
  padding: 0px;
  margin: 0px;
  border: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

div.leaflet-popup-content h6{
  font-size: 13px;
  border: 0px !important;
  padding: 0px !important;
  margin-bottom: 2px !important;
}

.leaflet-control-layers-overlays span{
    color: #000;
    /*-webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #000;  */
    /*text-shadow: 1px 1px black;*/
    /*text-shadow:#000 1px -1px, #000 -1px 1px, #000 1px 1px, #000 -1px -1px;*/
}

.legend {
    background-color: rgba(37, 37, 37, 0.7);
    margin-bottom: 10px;
  }
  
  .legend div {
    min-width: 167px; 
    max-height: 40px;
  }
  
  .legend table {
    margin-left: 15px; 
    margin-top:7px; 
    width:120px; 
    max-height:15px;
  }
  
  .tableEscala table tbody tr td{
    font-size: 11px;
  }
  
  .tableEscala table tr th{
    min-height: 5px;
    min-width: 5px;
    font-size: 5px;
  }
  
  .legend2 {
    background-color: rgba(37, 37, 37, 0.7);
    margin-bottom: 10px;
  }
  
  .legend2 div {
    min-width: 160px; 
    max-height: 40px;
  }
  
  .legend2 table tbody tr td{
    font-size: 11px;
  }
  
  .legend2 table {
    margin-left: 15px; 
    margin-top:7px; 
    min-width:130px; 
    max-height:15px;
  }
  
  .legend2 table tr th{
    min-height: 5px;
    min-width: 15px;
    font-size: 5px;
  }
  
  .legend3 {
    background-color: rgba(37, 37, 37, 0.7);
    margin-bottom: 10px;
  }
  
  .legend3 div {
    min-width: 185px; 
    max-height: 40px;
  }
  
  .legend3 table {
    margin-left: 15px; 
    margin-top:7px; 
    min-width:100px; 
    max-height:15px;
  }
  
  .legend3 table tbody tr td{
    font-size: 11px;
  }
  
  .legend3 table tr th{
    min-height: 5px;
    min-width: 15px;
    font-size: 5px;
  }
  
  .leaflet-popup-content{
    font-size: 1rem;
    line-height: 10%;
    min-width: 420px;
   /* max-width: 420px;*/
  }

  .container_Mapa.container{
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    font-size: 13px;
  }

  .layer_descricao{
    height: 20px;
    min-width: 30px;
    max-width: 400px;
    z-index: 10000;
    position: absolute;
    top: 10px;
    right: 10px;
    padding-top: 2px;
    padding-left:10px;
    padding-right: 10px;
    border-radius: 10px;
  }

  /*canvas[role=img]{
    aspect-ratio: 2 / 1;
    max-height: 220px;
  }*/

  .popup-with-no-events {
    pointer-events: none;
    min-width: 190px;
    max-width: 190px;
  }

  .range_container input[type='range']::-webkit-slider-runnable-track {
    z-index: 5;
    -webkit-appearance: none;
    height: 7px;
    margin-top: -1px;
  }
  
  .range_container input[type='range']::-moz-range-track {
    z-index: 5;
    -moz-appearance: none;
    height: 10px;
    margin-top: -1px;
  }
  
  .range_container input[type='range']::-ms-track {
    z-index: 5;
    appearance: none;
    height: 10px;
    margin-top: -1px;
  }

  .active_values_sliders{
    z-index: 5;
    background-color: #0075ff;
    min-width: 90%;
    min-height: 5px;
    left: 5%;
    z-index: 1;
    position: absolute;
    border-radius: 10px;
  }

  .slider__range {
    background-color: #0075ff;
    z-index: 2;
    position: absolute;
    border-radius: 5px;
    height: 7px;
  }

  #fromSlider {
    height: 0;
    z-index: 5;
    top: 3px;
  }

  .range_container input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    z-index: 5;
    width: 12px;
    height: 12px;
    margin-top: -2px;
    background-color: #0075ff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #0075ff;
    cursor: pointer;
  
  }
  
  .range_container input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    pointer-events: all;
    z-index: 5;
    width: 12px;
    height: 12px;
    margin-top: -2px;
    background-color: #0075ff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #0075ff;
    cursor: pointer;
  }
  
  .range_container input[type=range]::-webkit-slider-thumb:hover {
    z-index: 5;
    -webkit-appearance: none;
    background: #f7f7f7;
  }
  
  .range_container input[type=range]::-webkit-slider-thumb:active {
    z-index: 5;
    -webkit-appearance: none;
    box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
    -webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
  }
  
  .range_container input[type="range"] {
    z-index: 5;
    -webkit-appearance: none;
    appearance: none;
    height: 7px;
    width: 100%;
    margin-left: 0px;
    position: absolute;
    background-color: transparent;
    pointer-events: none;
  }

  
#demo-simple-select{
    min-width: 190px;
    min-height: 10px;
    max-height: 10px;
    font-size: 14px;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  
  .MuiInputBase-input  {
    font-size: 13px !important;
    height: 10px;
    margin-bottom: 10px;
  } 
  
  li.MuiButtonBase-root{
    font-size: 13px;
  }
  
  .MuiSlider-thumb {
    max-height: 15px;
    max-width: 15px;
    color: #0075ff;
  }
  
  #sel_data span.MuiSlider-track{
    color: rgba(56, 123, 190, 0);
    min-height: 0px;
    visibility: none;
  } 

  .ocultar{
    display: none;
  }

  thead tr th {
    vertical-align: top;
    font-size: 13px;
  }

  #disable_checkbox{
    display:block;
    pointer-events: none;
  }
  
  tbody tr td {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .form_control {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: #635a5a;
  }

  #controle-selecao{
    margin-left: 15px;
  }

  #controle-selecao-indicador{
    margin-left: 15px;
  }

  #multipleselect_form{
    margin-left: 15px;
  }

  .div_top_clima{
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap; /* Permite que os itens vão para a linha de baixo quando necessário */
    gap: 10px; /* Define o espaço entre os itens */
    /*
    display: flex;
    flex-wrap: wrap; 
    gap: 10px; */
  }

  @media screen and (max-width: 995px) {
    .div_top_clima{
      margin-bottom: 15px;
      padding-top: 0px;
    }  

    #controle-selecao{
      margin-left: 15px;
      margin-top: 10px;
      margin-right: 15px;
    }

    #controle-selecao-indicador{
      margin-left: 0px;
      margin-top: 10px;
    }

    #multipleselect_form{
      margin-top: 10px;
      margin-left: 0px;
    }

    .MuiBox-root{
      max-width: 45%;
      padding-bottom: 0px;
      margin-top: 0px;
    }

    .divLimpaFiltros{
      margin-top: 5px;
      margin-left: 10px;
      right: 45px;
      width: 25px;
      height: 25px;
      border-radius: 3px;
    }

    
    .divExportPlanilhaCSV{
      margin-top: -25px;
      margin-left: 45px;
      width: 25px;
      height: 25px;
      border-radius: 3px;
    }
    
    .divExportPlanilhaExcel{
      margin-top: -25px;
      margin-left: 85px;
      width: 25px;
      height: 25px;
      border-radius: 3px;
    }

}