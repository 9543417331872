.container_retangulo{
    flex:1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 15px;
    margin-top: 10px;
    max-width: 100%;
    min-width: 175px;
    min-height: 100px;
    /*max-height: 150px;*/
    max-height: 120px;
    border-radius: 10px;
}

.triangle_up {
    margin-left: 40%;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 30px solid #666666;
}

.triangle_down {
    margin-left: 40%;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 30px solid #666666;
}

.seta{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.seta_valor{
    flex: 1;
    font-size: 2.0em;
}

.container_retangulo:first-child{
    margin-left: 0px;
}

.container_retangulo:not(:last-child){
    margin-bottom: 5px;
}

.nome_variavel_retangulo{
    text-align: center;
    font-size: 15px;
    min-height: 25px;
    color: #fff;
    font-weight: bold;
    border-bottom: 0px;
    margin-top: 7px;
}

.valor_variavel_retangulo{
    margin-right: 5px;
    text-align: center;
    /*font-size: 36px;*/
    /*font-size:26px;*/
    font-family: Arial, Helvetica, sans-serif;
    font-size: 4.0em;
    font-weight: 600;
    margin-top: 0px;
    color: #fff;
}

@media screen and (max-width: 995px) {
    .valor_variavel_retangulo{
        margin-right: 5px;
        text-align: center;
        font-size: 35px;
        font-weight: 600;
    }

    .nome_variavel_retangulo{
        text-align: center;
        font-size: 13px;
        min-height: 30px;
    }
  }