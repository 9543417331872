.nav_abas{
  margin-top: 20px;
  min-width: 100%;
  z-index: 20;
}

.space_nav_abas{
  min-width: 100%;
  min-height: 30px;
}

.div_help{
  margin-left: auto;
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-width: 35px;
}

.img_help{
  margin-left: auto;
  margin-top: 10px;
  max-width:25px;
  max-height:25px;
}


  
      /* Estilo do link principal */
      .menu-button {
        background-color: #990000;
        font-weight: bold;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        display: flex;
        margin-left: 0px;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        height: 40px;
        text-align: center;
        margin-right: 20px;
        font-size: 18px;
      }
  
      /* Estilo do link principal */
      .menu-button2 {
        background-color: #000099;
        font-weight: bold;
        color: white;
        padding: 10px 20px;
        margin-left: 70px;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 200px;
        justify-content: space-between;
        font-size: 18px;
        height: 40px;
      }

      /* Estilo da seta */
      .arrow {
        margin-left: 8px;
        transition: transform 0.3s;
      }
  
      /* Painel oculto inicialmente */
      .submenu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0px;
        background-color: #f9f9f9;
        background-color: #990000;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        width: 200px;
        box-sizing: border-box;
        grid-template-columns:  1fr;
        height: 230px;
        font-size: 14px;
        font-weight: bold;
        
      z-index: 20;
      }

      .submenu-button{
        font-weight: bold;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        display: flex;
        margin-left: 0px;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        height: 40px;
        text-align: center;
        margin-right: 2px;
        font-size: 15px;
      }

      .submenu-button:hover {
        color: white;
        background-color: rgba(255,255,255,0.5);
        text-decoration-color: #990000;
      }
  
      /* Estilo dos links dentro do painel */
      .submenu a {
        color: white;
        padding: 12px 16px;
        padding-top: 18px;
        text-decoration: none;
        display: block;
        text-align: center;
        text-decoration: underline;
      }
  
      .submenu a:hover {
        color: white;
        background-color: rgba(255,255,255,0.5);
        text-decoration-color: #990000;
      }

      .submenu a:not(:last-child) {
        border-bottom: 2px solid rgba(109, 0, 0, 0.3); 
      }

      .linha_sup_menu{
        display: flex;
        width: 100%;
        height: 40px;
      }

      .menu {
        position: relative;
        /*display: inline-block;*/
        font-family: Arial, sans-serif;
        text-align: center;
      }
  
      /* Exibe o painel e rotaciona a seta ao passar o mouse */
      .menu:hover .submenu {
        display: grid;
      }
  
      .menu:hover .arrow {
        transform: rotate(180deg);
      }

    
      /* Estilo básico do menu */
      .menu2 {
        position: relative;
        /*display: inline-block;*/
        font-family: Arial, sans-serif;
        
        text-decoration: 'none';
      }

      .menu2 a{
        text-decoration: 'none';
      }
  
      /* Estilo da seta */
      .arrow {
        margin-left: 3px;
        transition: transform 0.3s;
        filter: invert(1);
        width: 20px;
        height: 20px;
      }
  
      /* Painel oculto inicialmente */
      .submenu2 {
        display: none;
        position: absolute;
        top: 100%;
        left: 0px;
        background-color: #000099;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        width: 250px;
        padding: 10px;
        box-sizing: border-box;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
      }
  
      /* Estilo dos links dentro do painel */
      .submenu2 a {
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: center;
        text-decoration: underline;
      }
  
      .submenu2 a:hover {
        background-color: rgba(255,255,255,0.5);
        text-decoration-color: #000099; 
      }
  
      /* Exibe o painel e rotaciona a seta ao passar o mouse*/ 
      .menu2:hover .submenu2 {
        display: grid;
      }
  
      .menu2:hover .arrow {
        transform: rotate(180deg);
      }

      .menu3 {
        position: relative;
        display: inline-block;
        font-family: Arial, sans-serif;
        text-align: center;
      }
  
      /* Exibe o painel e rotaciona a seta ao passar o mouse*/
      .menu3:hover .submenu {
        display: grid;
      }
  
      .menu3:hover .arrow {
        transform: rotate(180deg);
      }

      /* Estilo básico do menu */
      .menu4 {
        position: relative;
        display: inline-block;
        font-family: Arial, sans-serif;
  
      }

      .active_button{
        color: white;
        background-color: #990000;
      }

      .deactive_button{
        color: rgba(255,255,255,0.5);
        background-color:rgba(160, 12, 12, 0.397);
      }

      .submenu4-button{
        font-weight: bold;
        color: white;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        display: flex;
        margin-left: 0px;
        align-items: center;
        justify-content: space-between;
        width: 210px;
        height: 40px;
        text-align: center;
        font-size: 15px;
      }
  
      /* Estilo dos links dentro do painel */
      .submenu4 a {
        color: white;
        padding: 12px 16px;
        padding-top: 18px;
        text-decoration: none;
        display: block;
        text-align: center;
        text-decoration: underline;
        
      }

      .submenu4-button:hover {
        color: white;
        background-color: rgba(255,255,255,0.5);
        text-decoration-color: #990000;
      }

      