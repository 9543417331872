.container_retangulo{
    flex:1;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
    min-width: 175px;
    min-height: 100px;
    max-height: 150px;
    border-radius: 10px;
}

.container_retangulo:not(:last-child){
    margin-bottom: 5px;
}

.nome_variavel_retangulo{
    text-align: center;
    font-size: 15px;
    min-height: 45px;
    color: #fff;
    font-weight: bold;
}

.valor_variavel_retangulo{
    margin-right: 5px;
    text-align: center;
    /*font-size: 36px;*/
    /*font-size:26px;*/
    font-size: 2.5em;
    font-weight: 600;
    margin-top: 0px;
    color: #fff;
}

@media screen and (max-width: 995px) {
    .valor_variavel_retangulo{
        margin-right: 5px;
        text-align: center;
        font-size: 35px;
        font-weight: 600;
    }

    .nome_variavel_retangulo{
        text-align: center;
        font-size: 13px;
        min-height: 30px;
    }
  }