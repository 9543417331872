#container{
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    font-size: 13px;
}

.tabelas{
    
}

.table {
    padding: 0;
    padding-left: 2.5%;
    padding-right: 2.5%;
    max-height: 523px;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    color: #000;
    position: relative;
    z-index: 1;
    border: 0px;
  }

  .table thead {
    position: sticky; /* make the table heads sticky */
    top: 0px; /* table head will be placed from the top of the table and sticks to it */
    background-color: #f5f7f7;
    margin: 0px;
    border: 0px;
  }

  .invert {
    filter: hue-rotate(90deg);
  }
  